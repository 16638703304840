import * as React from "react";
import { css } from "@emotion/core";

const green = "#00D959";
const Environment: React.FC = () => {
  return (
    <div
      css={css`
        background: white
          url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23ccf7dd" viewBox="0 0 372.3 372.3" style="enable-background:new 0 0 372.3 372.3" xml:space="preserve"><path d="M298.1 91.35c-1.6-2-4.4-2.8-7.2-1.6l-10.4 4-32-70.8c-1.2-2.4-3.2-3.6-5.6-3.6h-104c-2 0-3.6.8-4.8 2.4l-58.8 70.4c-1.2 1.2-1.6 3.2-1.2 4.8.4 1.6 1.2 3.2 2.4 4.4l58.8 43.6c2.8 2 6.8 1.6 8.8-1.2l38.4-52 18.4 32.8-11.6 6c-2.4 1.2-3.6 3.6-3.2 6 .4 2.4 2 4.8 4.8 5.2l65.6 16h1.6c2 0 4-1.2 5.2-2.8l35.2-56.4c1.6-2.4 1.2-5.2-.4-7.2zm-160.4 39.6-48.4-36 52.8-63.2h8.4l26 48.4-38.8 50.8zm116.8 14-44.4-10.8 2.4-1.2c1.6-.8 2.8-2 3.2-3.6.4-1.6.4-3.2-.4-4.8l-26.4-47.2-24.8-46h74.4l32.8 72.8c1.2 2.4 4 4 6.4 3.6l-23.2 37.2zM159.7 252.95l-64.8-2.8 16.8-33.6 11.2 6.4c2.4 1.2 5.2 1.2 7.2-.8 2-1.6 2.8-4.4 1.6-6.8l-23.6-63.2c-.8-2.4-3.2-4-6-4l-66.8 2.4c-2.8 0-4.8 2-5.6 4.4-.8 2.4.4 5.2 2.4 6.8l9.2 6.4-40.4 66.4c-1.2 2-1.2 4.8 0 6.8l44.8 66 17.2 26c1.2 1.6 3.2 2.8 5.2 2.8h91.2c3.6 0 6.4-2.8 6.4-6.4v-70.4c0-3.2-2.8-6-6-6.4zm-104.4 46.4-41.6-61.6 42-68c1.6-2.4 1.2-5.2-.8-7.2l43.6-2 16 43.2-2.4-1.2c-1.6-.8-3.2-1.2-4.8-.4-1.6.4-2.8 1.6-3.6 3.2l-24 48-24.4 46zm97.6 24.4H71.3l-8-12.4 25.6-48.8 64 2.8v58.4zM371.7 232.15l-40-82.4c-.8-1.6-2-2.8-3.6-3.2-1.6-.4-3.2-.4-4.8.8l-64 35.6c-2.8 1.6-4 5.6-2.4 8.4l31.2 56.4-37.6 3.2-.4-12.8c0-2.8-2-4.8-4.4-5.6-2.4-.8-5.2 0-6.8 2l-41.6 53.2c-1.6 2-1.6 5.2-.4 7.2l37.2 55.2c1.2 1.6 3.2 2.8 5.2 2.8.4 0 1.2 0 1.6-.4 2.4-.8 4.4-3.2 4.4-5.6l.4-11.2h77.6c2.4 0 4.8-1.6 5.6-3.6l42.8-94.8c.8-1.6.8-3.6 0-5.2zm-51.6 91.6h-79.6c-2.8 0-5.2 2-6 4.4l-24.4-36.4 28.4-36.4v2.8c0 1.6.8 3.2 2 4.4 1.2 1.2 2.8 1.6 4.8 1.6l53.6-4.8 52-3.6-30.8 68zm36-81.2-54.8 4-30.8-56 52.8-29.6 36.4 74-3.6 7.6z"/></svg>')
          no-repeat right 5% bottom 5%;
        border-radius: 5px;
        border: 1px solid ${green};
        padding: 1em;
      `}
    >
      <p
        css={css`
          border-bottom: 3px solid ${green};
          font-weight: bold;
          font-size: large;
          margin-bottom: 1rem;
        `}
      >
        プラスチック製容器はリサイクルを
      </p>
      <p
        css={css`
          line-height: 1.5;
        `}
      >
        プラスチックごみは海の生態系などに甚大な影響を与えていると言われています。
        <br />
        食べ終わった後のプラスチック製容器は、きれいに洗って、資源物ごみに出しましょう。
      </p>
    </div>
  );
};

export default Environment;
